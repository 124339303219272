  
import { NavLink, Outlet, Link } from "react-router-dom";
import BannerBottom from "../../components/BannerBottom";
import { Div, ServicePage, Sidebar } from "./serviceStyle";

const Services = () => {
 

  return (
    <>
      <div id="slider1" className="carousel slide" data-bs-ride="carousel">
        <div className="carousel-inner">
          <div className="carousel-item position-relative active">
            <img
              src={require("../../assets/img/slider1.jpg")}
              className="d-block w-100"
              style={{ maxHeight: "60vh", objectFit: "cover" }}
              alt="..."
            />
            <div className="carousel-data d-none d-md-block text-center">
              <h1 data-aos="fade-right">Experienced Team</h1>
              <p data-aos="fade-right">
                We are a team of qualified software developers, aimed at
                creating unique and powerful tools for your business & everyday
                life.
              </p>

              <div className="bottom-btns" data-aos="fade-up">
                <Link to="/contact" className="touch px-4">
                  get in touch
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item position-relative">
            <img
              src={require("../../assets/img/slider2.jpg")}
              className="d-block w-100"
              style={{ maxHeight: "60vh", objectFit: "cover" }}
              alt="..."
            />

            <div className="carousel-data d-none d-md-block text-center">
              <h1 data-aos="fade-right">Award-Winning Software</h1>
              <p data-aos="fade-right">
                The software solutions developed by our company have been
                numerously awarded for usability and innovative features.
              </p>

              <div className="bottom-btns" data-aos="fade-up">
                <Link to="/contact" className="touch px-4">
                  get in touch
                </Link>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <img
              src={require("../../assets/img/slider3.jpg")}
              className="d-block w-100"
              style={{ maxHeight: "60vh", objectFit: "cover" }}
              alt="..."
            />
            <div class="carousel-data d-none d-md-block text-center">
              <h1 data-aos="fade-right">Mobile App Development</h1>
              <p data-aos="fade-right">
                Since our establishment, we have been delivering high-quality
                and sustainable software solutions for corporate purposes of
                worldwide businesses.
              </p>

              <div className="bottom-btns" data-aos="fade-up">
                <Link to="/contact" className="touch px-4">
                  get in touch
                </Link>
              </div>
            </div>
          </div>
        </div>
        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#slider1"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>
        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#slider1"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>

      <BannerBottom />

      <ServicePage>
        <Div className="container">
          <Div className="row">
            <Div className="col-md-12">
              <Sidebar>
                <ul>
                  <li>
                    <NavLink to="" end="true">
                      Website Development
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="android-dev" end="true">
                      Mobile App Development
                    </NavLink>
                  </li>

                  <li>
                    <NavLink to="digital-marketting" end="true">
                      Digital Marketting
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="smart-home" end="true">
                      Smart Home Services
                    </NavLink>
                  </li>
                   
                </ul>
              </Sidebar>
            </Div>

            <Div className="col-md-12">
              <Outlet />
            </Div>
          </Div>
        </Div>
      </ServicePage>
    </>
  );
};

export default Services;
