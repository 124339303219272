import React, { useState } from "react";
import { MdArrowForwardIos } from "react-icons/md";
import { HiPhone, HiOutlineLocationMarker } from "react-icons/hi";
import { BsEnvelope } from "react-icons/bs";
import $ from "jquery";

const Contact = () => {
  const [data, setData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [checkbox, setCheckbox] = useState([]);

  const checkHandler = (e) => {
    const { value } = e.target;
    const index = checkbox.indexOf(value);
    if (index === -1) {
      // value is not in the array, add it
      setCheckbox([...checkbox, value]);
    } else {
      // value is already in the array, remove it
      setCheckbox(checkbox.filter((v) => v !== value));
    }
  };
  console.log(checkbox);

  const changeHandler = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
    console.log(data);
  };
  const submit = (e) => {
    e.preventDefault();

    $(".wait").slideDown();

    $.ajax({
      method: "POST",
      url: `https://formsubmit.co/ajax/ron@eliteweb.solutions`,
      dataType: "json",
      accepts: "application/json",
      data: {
        name: data.name,
        email: data.email,
        phone: data.phone,
        message: data.message,
        services: checkbox,
      },
      success: (data) => {
        console.log("success");
        $(".wait").slideUp();
        $(".thanks").slideDown().delay(1000).slideUp()
      },
      error: (err) => {
        console.log("error");
      },
    });
  };
  return (
    <>
      <div className="contact-banner">
        <div className="container">

          <div className="row">
            <div className="col-lg-6">
            <h1 className="contact-heading">Let's <span>Talk</span> </h1>
          <p className="contact-para">
          Want to work with us? Whether you're starting from scratch or
            launching a product-let’s level up your brand,together!
          </p>
            </div>
          </div>
          
        </div>
      </div>

      <div className="benefits">
        <div className="container">
          <div className="row">
            <div className="col-md-4 mt-3">
              <h1>Benefits of a Consultation</h1>
            </div>

            <div className="col-md-8 mt-3">
              <ul className="contact-ul">
                <li>
                  <MdArrowForwardIos className="contact-icon" /> 1 hour with an
                  experience web strategist ($100 value)
                </li>
                <li>
                  <MdArrowForwardIos className="contact-icon" /> Analysis of
                  your current website
                </li>
                <li>
                  <MdArrowForwardIos className="contact-icon" /> Detailed look
                  into your competition
                </li>
                <li>
                  <MdArrowForwardIos className="contact-icon" /> Walk-through of
                  your best opportunities
                </li>
                <li>
                  <MdArrowForwardIos className="contact-icon" /> Tailored
                  suggestions based on your goals
                </li>
                <li>
                  <MdArrowForwardIos className="contact-icon" /> Prompt
                  follow-up quote detailing recommended strategies
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="contact-form">
        <div className="container">
          <div className="row pb-4">
            <div className="col-md-4 mb-3">
              <a href="mailto:ron@eliteweb.solutions" className="text-none">
                <div className="contact-card">
                  <div className="contact-icon">
                    <HiPhone className="icon" />
                  </div>

                  <h5>Phone</h5>
                  <h4>919-373-2882</h4>
                </div>
              </a>
            </div>

            <div className="col-md-4 mb-3">
              <a href="tel:919-373-2882" className="text-none">
                <div className="contact-card">
                  <div className="contact-icon">
                    <BsEnvelope className="icon" />
                  </div>

                  <h5>Email</h5>
                  <h4>ron@eliteweb.solutions</h4>
                </div>
              </a>
            </div>

            <div className="col-md-4 mb-3">
              <div className="contact-card">
                <div className="contact-icon">
                  <HiOutlineLocationMarker className="icon" />
                </div>

                <h5>Location</h5>
                <h4 className="text-center">
                  800 Park Offices Drive, <br /> Ste 3404, Research Triangle
                  Park, NC 27709
                </h4>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="form">
                <div className="row">
                  <div className="col-md-8 mx-auto">
                    <h3 className="text-center">Contact Us</h3>

                    <p>
                      We're looking forward to hearing from you. Fill out the
                      form below, and we'll get back to you as soon as we can.
                    </p>
                    <form action="" autoComplete="abc" onSubmit={submit}>
                      <div className="form-group">
                        <label htmlFor="name" className="label">
                          Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="custom-filed"
                          placeholder="Name"
                          name="name"
                          required
                          value={data.name}
                          onChange={changeHandler}
                        />
                      </div>

                      <div className="row">
                        <div className="col-md-6 mt-3">
                          <div className="form-group">
                            <label htmlFor="email" className="label">
                              Email  <span className="text-danger">*</span>
                            </label>
                            <input
                              type="email"
                              id="email"
                              required
                              className="custom-filed"
                              placeholder="abc@gmail.com"
                              name="message"
                              value={data.message}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>

                        <div className="col-md-6 mt-3">
                          <div className="form-group">
                            <label htmlFor="phone" className="label">
                              Phone  <span className="text-danger">*</span>
                            </label>
                            <input
                              id="phone"
                              type="text"
                              className="custom-filed"
                              placeholder="123-223-2323"
                              name="phone"
                              required
                              value={data.phone}
                              onChange={changeHandler}
                            />
                          </div>
                        </div>

                        <div className="col-md-12  mt-3">
                          <div className="check-container">
                            <input
                              type="checkbox"
                              checked={checkbox.includes("web-design")}
                              value="web-design"
                              id="web-design"
                              className="custom-checkbox"
                              onChange={checkHandler}
                            />
                            <label htmlFor="web-design" className="check-label">
                              Website Design
                            </label>
                          </div>

                          <div className="check-container">
                            <input
                              type="checkbox"
                              id="web-dev"
                              value="web-dev"
                              checked={checkbox.includes("web-dev")}
                              className="custom-checkbox"
                              onChange={checkHandler}
                            />
                            <label htmlFor="web-dev" className="check-label">
                              Website Development{" "}
                            </label>
                          </div>

                          <div className="check-container">
                            <input
                              type="checkbox"
                              id="digital-mark"
                              className="custom-checkbox"
                              value="digital marketing"
                              checked={checkbox.includes("digital marketing")}
                              onChange={checkHandler}
                            />
                            <label
                              htmlFor="digital-mark"
                              className="check-label"
                            >
                              Digital Marketting
                            </label>
                          </div>

                          <div className="check-container">
                            <input
                              type="checkbox"
                              id="mobile-app"
                              className="custom-checkbox"
                              value="mobile app"
                              checked={checkbox.includes("mobile app")}
                              onChange={checkHandler}
                            />
                            <label htmlFor="mobile-app" className="check-label">
                              Mobile App Dev
                            </label>
                          </div>

                          <div className="check-container">
                            <input
                              type="checkbox"
                              id="smarthome"
                              className="custom-checkbox"
                              value="smarthome"
                              checked={checkbox.includes("smarthome")}
                              onChange={checkHandler}
                            />
                            <label htmlFor="smarthome" className="check-label">
                              Smart Homes Service
                            </label>
                          </div>

                          <div className="check-container">
                            <input
                              type="checkbox"
                              id="seo"
                              className="custom-checkbox"
                              value="SEO"
                              checked={checkbox.includes("SEO")}
                              onChange={checkHandler}
                            />
                            <label htmlFor="seo" className="check-label">
                              Search Engine Optimization (SEO)
                            </label>
                          </div>
                        </div>

                        <div className="col-md-12 mt-3">
                          <div className="form-group">
                            <label htmlFor="message" className="label">
                              Message  <span className="text-danger">*</span>
                            </label>
                            <textarea
                              id="message"
                              rows="6"
                              required
                              className="custom-filed"
                              placeholder="Enter Your Message"
                              name="email"
                              value={data.email}
                              onChange={changeHandler}
                            ></textarea>
                          </div>
                        </div>

                        <p className="text-danger wait hide">Please wait...</p>

                        <p className="thanks text-info hide">Thanks for contacting. We will contact you soon! </p>

                        <div className="col-md-12 mt-3">
                          <button type="submit" className="submit-btn">
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
