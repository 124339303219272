import React, { useEffect, useState } from "react";
import { Div, Webcard } from "./serviceStyle";
import { Link } from "react-router-dom";
import sanityClient from "../../client";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
const Android = () => {
  const [android, setAndroid] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "androiddev"]{
      name,
      link,
      description,
      paratwo,
      features,
      image{
        asset->{
          _id,
          url
        },
      },
      bannerimage{
        asset->{
          _id,
          url
        },
      },
      
     
          
    }`
      )
      .then((data) => setAndroid(data))
      .catch(console.error);
  }, []);

  

  return (
    <Div>
      <Div className="row">
        <Div className="col-md-12 mt-3 mt-md-0">
          {/* <WebDevBanner >
            <Webul>
              {android !== null
                ? android.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href={`#${item.link}`}>{item.name}</a>
                      </li>
                    );
                  })
                : null}
            </Webul>
          </WebDevBanner> */}
        </Div>

        <ResponsiveMasonry
          columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2,1200:3 }}
        >
          <Masonry gutter="1.8rem">
            {android !== null ? (
              android
                .slice()
                .reverse()
                .map((item, index) => {
                  return (
                    <Webcard key={index}>
                       <Link to="/details"
                        state= {{ item: item}}>
                        <img
                            src={item.image.asset.url}
                            alt={item.name}
                            className="img-fluid w-100"
                          />
                        </Link>

                      <Div className="  px-2 py-3">
                      <Link to="/details"                               
                              state= {{ item: item } }
                              className="webdev-link">
                              <h5 className="m-0">{item.name}</h5>
                            </Link>
                        <p>{item.description}</p>

                       
                      </Div>
                    </Webcard>
                  );
                })
            ) : (
              <>
                <div>Loading...</div>
              </>
            )}
          </Masonry>
        </ResponsiveMasonry>
      </Div>
    </Div>
  );
};

export default Android;
