import React from "react";
import styled from "styled-components";

const BannerBottom = () => {

  const Banner=styled.div`
  background-color: #F5F6F8;
  padding: 3rem 0;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  
   
  
  ul{
    list-style: none;
    text-align: center;
    margin: 0;
    li{
      display: inline-block;
      margin:0 1rem;
      wrap: nowrap;
      img{
        max-width: 8.8rem;
      }
    }
  }
  @media all and (max-width: 768px) {
    padding: 1rem 0;
    ul{
      li{
        img{
          max-width: 5.8rem;
        }
      }
    }
  }
  `
  return (
    <Banner >
      <marquee behavior="scroll" direction="left"><ul>
        <li>
          <img
            src={require("../assets/img/cnx.svg").default}
            alt="cnx"
            className="img-fluid"
          />
        </li>
        <li>
          <img
            src={require("../assets/img/docker.svg").default}
            alt="cnx"
            className="img-fluid"
          />
        </li>
        <li>
          <img
            src={require("../assets/img/paypal.svg").default}
            alt="cnx"
            className="img-fluid"
          />
        </li>

        <li>
          <img
            src={require("../assets/img/wordpress.png")}
            alt="cnx"
            className="img-fluid"
          />
        </li>

        <li>
          <img
            src={require("../assets/img/stripe.png")}
            alt="cnx"
            className="img-fluid"
          />
        </li>
        <li>
          <img
            src={require("../assets/img/cnx.svg").default}
            alt="cnx"
            className="img-fluid"
          />
        </li>
        <li>
          <img
            src={require("../assets/img/docker.svg").default}
            alt="cnx"
            className="img-fluid"
          />
        </li>
        <li>
          <img
            src={require("../assets/img/paypal.svg").default}
            alt="cnx"
            className="img-fluid"
          />
        </li>

        <li>
          <img
            src={require("../assets/img/wordpress.png")}
            alt="cnx"
            className="img-fluid"
          />
        </li>

        <li>
          <img
            src={require("../assets/img/stripe.png")}
            alt="cnx"
            className="img-fluid"
          />
        </li>
      </ul></marquee>
      
    </Banner>
  );
};

export default BannerBottom;
