import React, { useState, useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import AOS from "aos";
import { BsPlusCircleDotted } from "react-icons/bs";
import Frontend from "../../components/Frontend";
import sanityClient from "../../client";
 
import Expertise from "../../components/Expertise";

import {
  Section,
  Container,
  Div,
  HeadingOne,
  HeadingTwo,
  Anchor,
  AboutUs,
  H3,
  CustomCard,
  Box,
  ServiceCard,
   
  Technologies,
  
  ExpertiseDiv,
} from "./styles";
const Home = () => {
  const [showOne, setShowOne] = useState(false);
  const [landing, setLanding] = useState(null);

  const showHandelerOne = () => {
    setShowOne(!showOne);
  };

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "landing"]{
      mainheading,
      subheading,
      bottomheading,
      bottompara,
      bottomparatwo,     
      image{
        asset->{
          _id,
          url
        },
      },
      bannerimage{
        asset->{
          _id,
          url
        },
      },
   
    }`
      )
      .then((data) => setLanding(data))
      .catch(console.error);

    AOS.init({
      duration: 800,
    });
  }, []);

  console.log(landing);
  return (
    <>
      <Section>
        <Container>
          <Div className="container">
            <Div className="row">
              <Div className="col-lg-5 d-flex flex-column justify-content-end">
                <HeadingOne data-aos="fade-up">
                  {landing !== null && landing[0].mainheading}
                </HeadingOne>
                <HeadingTwo data-aos="fade-up" data-aos-delay="400">
                  {landing !== null && landing[0].subheading}
                </HeadingTwo>

                <Anchor data-aos="fade-up" data-aos-delay="600">
                  <Link to="/contact">Get Free Consultation</Link>
                </Anchor>

                {/* <Div
                  data-aos="fade-up"
                  data-aos-delay="800"
                  className="pt-4"
                >
                  <SocialIcons />
                </Div> */}
              </Div>
              <div className="col-lg-7 d-flex  justify-content-center  ">
                <img
                  src={require("../../assets/img/banner-right.webp")}
                  alt="web dev"
                  className="img-fluid"
                />
              </div>
            </Div>
          </Div>
        </Container>
      </Section>



<div className="banner-bottom">
  <div className="container">
   <div className="text-end bottom-text">
    <h1  >Let's Develop Your Next Great App!</h1>
    <h6 > Do you need a unique software solution for your company? We know how to help you!
    </h6>

    <div className="bottom-btns">
      <Link to="/contact" className="touch">get in touch</Link>
      <a href="#services" className="touch touch-one">all services</a>
    </div>
   </div>
  </div>
</div>

      <AboutUs>
        <Div className="container">
          <Div className="row py-4">
            <Div
              data-aos="fade-up"
              className="col-lg-6 mt-3 mt-lg-0 d-flex flex-column justify-content-center"
            >
              <H3>{landing !== null && landing[0].bottomheading}</H3>
              <p className="mt-4">
                {landing !== null && landing[0].bottompara}
              </p>

              <p>{landing !== null && landing[0].bottomparatwo}</p>

              <Anchor data-aos="fade-up" data-aos-delay="200">
                <Link to="/contact">Get Free Consultation</Link>
              </Anchor>
            </Div>

            <Div className="col-lg-6 mt-3 mt-lg-0">
              <Div className="row">
                <Div className="col-md-4 pt-md-5">
                  <CustomCard data-aos="fade-up" className="mt-3 mt-md-5">
                    <Box className="box-zero hover">
                      <BsPlusCircleDotted size={30} className="icon" />
                    </Box>
                    <h1>55</h1>
                    <p>High-skilled developers in our company</p>
                  </CustomCard>
                </Div>
                <Div className="col-md-4">
                  <CustomCard
                    data-aos="fade-up"
                    data-aos-delay="100"
                    className="custom-card card-two mt-3 mt-md-5"
                  >
                    <Box className="box-one hover">
                      <BsPlusCircleDotted size={30} className="icon" />
                    </Box>
                    <h1>140</h1>
                    <p>Finished and launched projects</p>
                  </CustomCard>
                  <CustomCard
                    data-aos="fade-up"
                    data-aos-delay="200"
                    className="mt-3 mt-md-2"
                  >
                    <Box className="box-two hover">
                      <BsPlusCircleDotted size={30} className="icon" />
                    </Box>
                    <h1>4</h1>
                    <p>5 years of experience in web & Mobile development</p>
                  </CustomCard>
                </Div>
                <Div className="col-md-4 ">
                  <CustomCard
                    data-aos="fade-up"
                    className="card-four mt-3 mt-md-0  "
                  >
                    <Box className="hover box-three">
                      <BsPlusCircleDotted size={30} className="icon" />
                    </Box>
                    <h1>70</h1>
                    <p>Satisfied Clients</p>
                  </CustomCard>
                  <CustomCard
                    data-aos="fade-up"
                    data-aos-delay="200"
                    className=" card-four mt-3 mt-md-2"
                  >
                    <Box className="hover box-four">
                      <BsPlusCircleDotted size={30} className="icon" />
                    </Box>
                    <h1>RTP</h1>
                    <p>WHERE PEOPLE + IDEAS CONVERGE</p>
                  </CustomCard>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </AboutUs>

      <Section className="services" id="services">
        <img
          src={require("../../assets/img/mobile.png")}
          className="abs-mobile"
          alt="circle"
        />

        <img
          src={require("../../assets/img/website.png")}
          className="abs-website"
          alt="circle"
        />
        <img
          src={require("../../assets/img/circle.png")}
          className="abs-circle"
          alt="circle"
        />

       

        <Div className="container">
          <Div className="row">
            <Div className="col-md-12">
              <h1 className="main-heading">Our Services</h1>
            </Div>
          </Div>

          <ServiceCard className="service-card-one service-one ">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="service-card-top h-100 p-2 p-lg-4 d-flex flex-column">
                  <h4 className="service-heading">Custom Web Development</h4>
                  <p>
                    In today's digital age, an online business has become
                    essential for businesses and individuals. However, with so
                    many websites, it's important to make sure yours stands out.
                    This is where custom website development comes in.
                  </p>
                  <ul>
                    <li>
                      <p className="m-0">SEO</p>
                    </li>
                    <li>
                      <p className="m-0">Email marketing</p>
                    </li>
                    <li>
                      <p className="m-0">Content marketing</p>
                    </li>
                    <li>
                      <p className="m-0">SMM</p>
                    </li>
                    <li>
                      <p className="m-0">Brand reputation</p>
                    </li>
                    <li>
                      <p className="m-0">App store optimization (ASO)</p>
                    </li>

                    <li>
                      <p className="m-0">PPC</p>
                    </li>
                  </ul>

                  <div className="mt-2 mt-md-5">
                    <NavLink to="/services" className="samrthome-link">
                      See Details
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <img
                  src={require("../../assets/img/testImg.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </ServiceCard>

          <ServiceCard className="service-card-one service-two ">
            <div className="row">
              <div className="col-lg-6 order-last order-lg-first mt-4 mt-lg-0">
                <img
                  src={require("../../assets/img/mobile-app.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="col-lg-6 order-first order-lg-last ">
                <div className="service-card-top h-100 p-2 p-lg-4 d-flex flex-column">
                  <h4 className="service-heading">MOBILE APP DEVELOPMENT</h4>
                  <p>
                    Mobile applications have become an integral part of our
                    daily lives. They help us stay connected, work, and access
                    information anytime, anywhere. As a result, businesses and
                    individuals are increasingly turning to mobile app
                    development to create custom mobile apps that meet their
                    unique needs.
                  </p>
                  <ul>
                    <li>
                      <p className="m-0">iOS App Development</p>
                    </li>
                    <li>
                      <p className="m-0">Android App Development</p>
                    </li>
                    <li>
                      <p className="m-0">React Native App Development</p>
                    </li>
                    <li>
                      <p className="m-0">Flutter App Development</p>
                    </li>
                  </ul>

                  <div className="mt-2 mt-md-5">
                    <NavLink
                      to="services/android-dev"
                      className="samrthome-link"
                    >
                      See Details
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </ServiceCard>

          <ServiceCard className="service-card-one service-three">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="service-card-top h-100 p-2 p-lg-4 d-flex flex-column">
                  <h4 className="service-heading">
                    Custom Smart Home Services
                  </h4>
                  <p>
                    Smart home technology has revolutionized the way we live,
                    allowing us to easily control and automate every aspect of
                    our home. Custom smart homes take this technology to the
                    next level by creating custom built-in systems that meet a
                    homeowner's unique needs and preferences.
                  </p>
                  <ul>
                    <li>
                      <p className="m-0">Network and Wi-Fi</p>
                    </li>
                    <li>
                      <p className="m-0">Whole Home Audio</p>
                    </li>
                    <li>
                      <p className="m-0">Home Theatre</p>
                    </li>
                    <li>
                      <p className="m-0">Security & Surveillance</p>
                    </li>

                    <li>
                      <p className="m-0">Smart Door Lock</p>
                    </li>

                    <li>
                      <p className="m-0">Custom smart homes</p>
                    </li>
                  </ul>

                  <div className="mt-2 mt-md-5">
                    <NavLink
                      to="/services/smart-home"
                      className="samrthome-link"
                    >
                      See Details
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <img
                  src={require("../../assets/img/smart-home.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </ServiceCard>

          <ServiceCard className="service-card-one service-four">
            <div className="row">
              <div className="col-lg-6 mt-4 mt-lg-0 order-last order-lg-first">
                <img
                  src={require("../../assets/img/digital-marketing.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="col-lg-6 order-first order-lg-last ">
                <div className="service-card-top h-100 p-2 p-lg-4 d-flex flex-column">
                  <h4 className="service-heading">
                    Digital marketing services
                  </h4>
                  <p>
                    In today's digital age, marketing has evolved to include a
                    range of online channels and strategies. Digital marketing
                    refers to the use of these channels and strategies to
                    promote a brand or product online and reach a wider
                    audience. Digital marketing covers a range of channels
                    including search engine optimization (SEO), social media,
                    email marketing, pay-per-click (PPC) advertising and more
                  </p>
                  <ul>
                    <li>
                      <p className="m-0">SEO</p>
                    </li>
                    <li>
                      <p className="m-0">Email marketing</p>
                    </li>
                    <li>
                      <p className="m-0">Content marketing</p>
                    </li>
                    <li>
                      <p className="m-0">SMM</p>
                    </li>
                    <li>
                      <p className="m-0">Brand reputation</p>
                    </li>
                    <li>
                      <p className="m-0">App store optimization (ASO)</p>
                    </li>

                    <li>
                      <p className="m-0">PPC</p>
                    </li>
                  </ul>

                  <div className="mt-2 mt-md-5">
                    <NavLink
                      to="/services/digital-marketting"
                      className="samrthome-link"
                    >
                      See Details
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </ServiceCard>

          <ServiceCard className="service-card-one service-five ">
            <div className="row">
              <div className="col-lg-6 ">
                <div className="service-card-top h-100 p-2 p-lg-4 d-flex flex-column">
                  <h4 className="service-heading">Graphics and UX Design</h4>
                  <p>
                    Graphics and user experience design are key to creating
                    engaging and effective digital products. Graphic design
                    involves the creation of visual elements such as logos,
                    icons, and images, while user experience design focuses on
                    creating user-centric experiences for digital products such
                    as websites, mobile applications and software.
                  </p>
                  <ul>
                    <li>
                      <p className="m-0">Figma and XD</p>
                    </li>
                    <li>
                      <p className="m-0">Photoshop</p>
                    </li>
                    <li>
                      <p className="m-0">Web & mobile design</p>
                    </li>
                    <li>
                      <p className="m-0">User experience design</p>
                    </li>
                    <li>
                      <p className="m-0">User interface design</p>
                    </li>

                    <li>
                      <p className="m-0">Frontend development</p>
                    </li>

                    <li>
                      <p className="m-0">Interaction design</p>
                    </li>
                  </ul>

                  <div className="mt-2 mt-md-5">
                    <NavLink to="/services" className="samrthome-link">
                      See Details
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-4 mt-lg-0">
                <img
                  src={require("../../assets/img/graphic-design.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>
            </div>
          </ServiceCard>

          <ServiceCard className="service-card-one service-six ">
            <div className="row">
              <div className="col-lg-6 mt-4 mt-lg-0 order-last order-lg-first">
                <img
                  src={require("../../assets/img/cms.webp")}
                  alt=""
                  className="img-fluid"
                />
              </div>

              <div className="col-lg-6 order-first order-lg-last">
                <div className="service-card-top h-100 p-2 p-lg-4 d-flex flex-column">
                  <h4 className="service-heading">Content Managment System</h4>
                  <p>
                    A content management system (CMS) is a software application
                    that allows businesses to create, manage, and publish
                    digital content on their websites, blogs, and other online
                    platforms. With a CMS, businesses can easily create and edit
                    content without the need for technical knowledge or coding
                    skills.
                  </p>
                  <ul>
                    <li>
                      <p className="m-0">Wordpress</p>
                    </li>
                    <li>
                      <p className="m-0">Shopify</p>
                    </li>
                    <li>
                      <p className="m-0">Wix</p>
                    </li>
                    <li>
                      <p className="m-0">Sanity</p>
                    </li>
                    <li>
                      <p className="m-0">Others</p>
                    </li>
                  </ul>
                  <div className="mt-2 mt-md-5">
                    <NavLink
                      to="/services/digital-marketting"
                      className="samrthome-link"
                    >
                      See Details
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </ServiceCard>
        </Div>
        <img
          src={require("../../assets/img/home.png")}
          className="abs-home"
          alt="circle"
        />
        

        <img
          src={require("../../assets/img/marketing.png")}
          className="abs-mark"
          alt="circle"
        />

      </Section>

      <Technologies>
        <Div className="container">
          <Div className="row">
            <Frontend />           
          </Div>
        </Div>
      </Technologies>

      <ExpertiseDiv>
        <Div className="container">
          <Div className="row">
            <Div className="col-md-12 pb-md-5">
              <h1 className="main-heading">
                Industrial Expertise of
                <br />
                <span> Elite Web Solutions</span>
              </h1>
            </Div>
            <Expertise />
          </Div>
        </Div>
      </ExpertiseDiv>
    </>
  );
};

export default Home;
