import React from "react";
import { Link } from "react-router-dom";
import logo from "../assets/img/logo.png";
import styled from "styled-components";
import { white,blue } from "./colors";
import { FaBars } from "react-icons/fa";
import { RxCrossCircled } from "react-icons/rx";
import { BsTelephoneFill } from "react-icons/bs";
const Navbar = () => {
  const [show, setShow] = React.useState(false);
  const Navbar = styled.section`
    background-color: #F7F6FC;
    position: relative;
    padding: 0.5rem 0;
    // box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    border-bottom: 1px solid #e6e6e6;
  `;
  const Navigation = styled.div`
    ul {
      list-style: none;
      padding: 0;
      margin: 0;
      @media all and (max-width: 992px) {
        display: none;
      }
      li {
        display: inline-block;
        padding: 15px;        
        a {
          text-decoration: none;
          color: #005EB8 !important;
          font-weight: 600;
          font-size: 1.1rem;
          letter-spacing: 1px;
          text-transform: capitalize;
        }
      }
    }
  `;

  const Logo = styled.div`
    a {
      img {
        max-width: 190px;
        @media all and (max-width: 992px) {
          max-width: 100px;
        }
      }
    }
  `;

  const Div = styled.div``;
  return (
    <>
      <Navbar className="fixed-top">
        <FaBars className="handburgur" onClick={() => setShow(true)} />

        <Div className="container">
          <Div className="d-flex justify-content-between align-items-center">
            <Logo>
              <Link to="/">
                <img src={logo} alt="ews" />
              </Link>
            </Logo>
            <Navigation>
              <ul>
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                {/* <li>
                  <Link to="/about">Company</Link>
                </li> */}
                
                {/* <li>
                  <Link to="/our-developers">Developers</Link>
                </li> */}
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </Navigation>
            <Div className="mobile-icon-container">
              <div className="mobile-icon">
                <a href="tel:919-373-2882">
                  <BsTelephoneFill className="mobile" />                  
                </a>
                <span>919-373-2882</span>
              </div>
            </Div>
          </Div>
        </Div>
      </Navbar>

      <div className={show ? `mobileNav navopen` : `mobileNav`}>
        <div className=" text-end">
          <RxCrossCircled
            className="cross-icon"
            onClick={() => setShow(false)}
          />
        </div>
        <ul>
          <li>
            <Link to="/" onClick={() => setShow(false)}>
              Home
            </Link>
          </li>
          {/* <li>
            <Link to="/about" onClick={() => setShow(false)}>
              About us
            </Link>
          </li> */}
          <li>
            <Link to="/services" onClick={() => setShow(false)}>
              Services
            </Link>
          </li>
          {/* <li>
            <Link to="/our-developers" onClick={() => setShow(false)}>
              Developers
            </Link>
          </li> */}
          <li>
            <Link to="/contact" onClick={() => setShow(false)}>
              Contact
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Navbar;
