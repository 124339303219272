import { BrowserRouter, Routes, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Contact from "./pages/contact/Contact";
import  Details from "./pages/details/Details";
import ServicDetails from "./pages/details/ServiceDetails";
import Home from "./pages/home/Home";
import Android from "./pages/services/Android";
import Marketting from "./pages/services/Marketting"; 
import Services from "./pages/services/Services";
import Smarthome from "./pages/services/Smarthome";
import Webdev from "./pages/services/Webdev";
import About from "./pages/about/About";
import Notfound from "./pages/notfound/Notfound";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/services" element={<Services />}>
          <Route index element={<Webdev />} />
          <Route path="android-dev" element={<Android />} />
         
          <Route path="digital-marketting" element={<Marketting/>} />
          <Route path="smart-home" element={<Smarthome/>} />
          <Route path="service-details" element={<ServicDetails/>} />
        </Route>       
        
        <Route path="/service-details" element={<ServicDetails/>} />
         
        <Route path="details" element={<Details/>} />
        <Route path="*" element={<Notfound/>} />
        
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
