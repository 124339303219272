import React from 'react'

const Notfound = () => {
  return (
    <div className='notfound'>
      <h1>Ooops!</h1>
      <h2>Page Not Found</h2>
    </div>
  )
}

export default Notfound