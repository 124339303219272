import React, { useEffect, useState } from "react";
import { LandinUl } from "../home/styles";
import {
  Div,
  Webul,
  WebDevBanner,
  Webcard,
} from "./serviceStyle";
import sanityClient from "../../client";
import { Link } from "react-router-dom";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
const Webdev = () => {
  const [webService, setWebService] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(
        `*[_type == "webdev"]{
      name,
      link,
      tech,
      description,
      paratwo,
      bannerpara,
      features,
     
      image{
        asset->{
          _id,
          url
        },
      },
      bannerimage{
        asset->{
          _id,
          url
        },
      },
   
    }`
      )
      .then((data) => setWebService(data))
      .catch(console.error);
  }, []);

 
 
  return (
    <>
      <Div>
        <Div className="row">
          {/* <Div className="col-md-12 mt-3 mt-md-0">
            <WebDevBanner>
              <Webul>
                {webService !== null
                  ? webService
                      .slice()
                      .reverse()
                      .map((item, index) => {
                        return (
                          <li key={index}>

                                    <Link to="/service-details"                               
                              state= {{ item: item } }
                              className="webdev-link">
                              {item.name}
                            </Link>
 
                          </li>
                        );
                      })
                  : null}
              </Webul>
            </WebDevBanner>
          </Div> */}

          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 2,1200:3  }}
          >
            <Masonry gutter="1.8rem">
              {webService !== null ? (
                webService
                  .slice()
                  .reverse()
                  .map((item, index) => {                     
                    return (
                      <Div key={index}>
                        <Webcard className="webdev-card">
                        <Link to="/service-details"
                        state= {{ item: item}}>
                        <img
                            src={item.image.asset.url}
                            alt={item.name}
                            className="img-fluid w-100"
                          />
                        </Link>
                        

                          <Div className=" px-2 py-3">
                            <Link to="/service-details"                               
                              state= {{ item: item } }
                              className="webdev-link">
                              <h5 className="m-0">{item.name}</h5>
                            </Link>
                            <p className="mt-2" >{item.description}</p> 
                            </Div>
                        </Webcard>
                      </Div>
                    );
                  })
              ) : (
                <>loading...</>
              )
              }
            </Masonry>
          </ResponsiveMasonry>
        </Div>
      </Div>
    </>
  );
};

export default Webdev;
