import styled from "styled-components";


export const Div = styled.div``;

export const ExpCard=styled.div`
text-align: center;
padding: 2rem 1rem;
min-height: 300px;
background-color: #e5eefd;
border-radius: 0.4rem;
`