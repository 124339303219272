import styled from "styled-components";
import { gray,blue,white } from "../../components/colors";
import bannerImg from "../../assets/img/web-banner.jpg";
import webdev from "../../assets/img/web-dev.png";
export const Div = styled.div``;

export const Webul=styled.ul`
padding: 0;
list-style: none;
li{
  display: inline-block;
  margin-right: 1rem;
  min-width: 300px;
  margin-bottom:1rem ;
  a{
    padding: 1rem;
    display: inline-block;
    width: 100%;
    text-decoration: none;
    color:${blue};
    border: 1px dashed ${blue};
  }
}
@media all and (max-width:992px){
  li{
    min-width: unset;
    a{
      padding: 0.7rem;
    }
  }
}
`;

export const WebHeadFive=styled.h5`
font-weight: 500;
color: ${gray};
`;

export const WebHeadOne=styled.h1`
padding-bottom: 0.5rem;
  margin-top: 1rem;
  font-size: 3rem;
  font-weight: bold;
  color: ${blue};
  @media all and (max-width: 768px) {
    font-size: 2rem;
  }
  `;

  export const DescPara=styled.p`
  color: ${gray};
  font-weight: 500;`;

  export const ContactBanner=styled.div` 
  max-height: 50vh;     
  background-color: #fff;  
   
  h1{     
    font-size: 4rem;          
  }
p{
  font-size: 1.3rem;
  color: #000;
   
}`;

export const ServicePage=styled.div`
padding: 3rem 0;
background-color: #fff;
position: relative;
@media all and (max-width: 768px) {
  padding: 3rem 0;
}`;

export const Sidebar=styled.div`
text-align: center;
margin-bottom: 3rem;


      
ul{
 list-style: none;
 padding: 0;
 margin: 0;

 li{    
  display: inline-block;
    a{
     text-decoration: none;
     display: inline-block;
     width: 100%;
     padding: 1rem;       
     
   }
   .active{
     color: ${white};
     background-color: ${blue};
     font-weight: 500;
   }
 }
}
@media all and (max-width: 768px) {
  text-align: left;
  margin-bottom: 1rem;
  background-color: #E5EEFD;
  padding: 1rem;
  ul{
    li{
      display: block;
      width: 100%;
    }
  }
   
}
`;

export const AndroidCard=styled.div`
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  background-color: #fff;
  padding: 2rem 1.3rem;
  min-height: 330px;
  @media all and (max-width:768px){
    padding: 1rem;
  }
  `;
  export const HeadingFour=styled.h4`
  margin-top: 1rem;
  color: $color-blue;
  text-transform: capitalize;
  font-weight: 700;`;



export const Webcard=styled.div`
box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
min-height: 500px;
border-radius: 5px;
overlow: hidden;
border-top: 0.3rem solid ${blue};

img{
  max-height: 250px;
}
`;
export const FeatureUl=styled.ul`
list-style: none;
padding: 0;
margin: 0;
li{
  display: inline-block;
  margin-right: 0.4rem;
  border-right: 1px solid ${gray};
  padding-right: 0.4rem;
  margin-bottom: 0.5rem;
}`

 export const WebDevBanner=styled.div`
 box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
background-size: cover;
background-position: center;
background-repeat: no-repeat;
padding: 2rem 1rem; 
margin-bottom: 2rem;`