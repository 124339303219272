import React from "react";
import { Link } from "react-router-dom";
import { MdArrowForwardIos } from "react-icons/md";
import SocialIcons from "./SocialIons";
const Footer = () => {
  return (
    <section className="footer">
      <div className="container">
        <div className="row">
          <div className="col-sm-6 col-md-3 mt-3">
            <h3 className="fw-bold">Our Services</h3>

            <ul className="footer-ul">
              <li>
                <Link to="/services">
                   
                  <MdArrowForwardIos className="footer-icon" /> Website Solutions
                </Link>
              </li>

              <li>
                <Link to="/services/android-dev">
                   
                  <MdArrowForwardIos className="footer-icon" /> Mobile apps
                  development
                </Link>
              </li>

              <li>
                <Link to="/services">
                   
                  <MdArrowForwardIos className="footer-icon" /> UX Design
                </Link>
              </li>

              <li>
                <Link to="/services/digital-marketting">
                   
                  <MdArrowForwardIos className="footer-icon" /> Digital
                  marketing service
                </Link>
              </li>

              <li>
                <Link to="/services/smart-home">
                   
                  <MdArrowForwardIos className="footer-icon" /> Smart Home
                  Services
                </Link>
              </li>

               

            

               
               
            </ul>
          </div>

          <div className="col-sm-6 col-md-3 mt-3">
            <h3 className="fw-bold">Smart Home Services</h3>

            <ul className="footer-ul">
              <li>
                <Link to="/services/smart-home">
                   
                  <MdArrowForwardIos className="footer-icon" /> Network and
                  Wi-Fi
                </Link>
              </li>

              <li>
                <Link to="/services/smart-home">
                   
                  <MdArrowForwardIos className="footer-icon" /> Whole Home Audio
                </Link>
              </li>

              <li>
                <Link to="/services/smart-home">
                   
                  <MdArrowForwardIos className="footer-icon" /> Home Theatre
                </Link>
              </li>

              <li>
                <Link to="/services/smart-home">
                   
                  <MdArrowForwardIos className="footer-icon" /> Security &
                  Surveillance
                </Link>
              </li>
             

              <li>
                <Link to="/services/smart-home">
                   
                  <MdArrowForwardIos className="footer-icon" /> Smart Door Lock
                </Link>
              </li>

              
            </ul>
          </div>

          

          <div className="col-sm-6 col-md-3 mt-3">
            <h3 className="fw-bold">Location</h3>

            

            <p className="footer-para">
              600 Park Offices Drive, <br /> Ste 300-234, Research Triangle
              Park, NC 27709 <br />
              <strong>Phone:</strong>
              <a href="tel:919-373-2882">919-373-2882</a> <br />
              <strong>Email: </strong>
              <a href="mailto:info@.ews">ron@eliteweb.solutions</a> <br />
            </p>

           
          </div>

          <div className="col-sm-6 col-md-3 mt-3">
            <h3 className="fw-bold">Contact Us</h3>

            <ul className="footer-ul">
              <li>
                <Link to="/contact">                 
                  <MdArrowForwardIos className="footer-icon" /> Contact Us
                </Link>
              </li>
            </ul>           

            <SocialIcons />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Footer;
