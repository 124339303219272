import React, {useEffect} from "react";
import { Link, useLocation } from "react-router-dom";
import { Anchor, LandinUl, Section } from "../home/styles";
import { Div, WebHeadOne } from "../services/serviceStyle";
import styled from "styled-components";

const  Details = (props) => {
  const location = useLocation();
  const { item } = location.state;
  console.log(item);
   
  const Banner = styled.div`
  background-color: #E5EEFD;     
    min-height: 50vh;
    display: flex;

    .left{
      flex:50% !important;
      padding: 0 2rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .right{
      flex:50% !important;
    }
    @media all and (max-width: 768px) {
 
      flex-direction: column-reverse;
      .left{
        padding: 1rem 0.5rem;
      }
    }
  `;


  useEffect(() => {
    if (location.hash) {
      let elem = document.getElementById(location.hash.slice(1));
      if (elem) {
        elem.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  }, [location]);

  return (
    <Section className="details-container">
       <Banner >      
        <div className="left">
          <WebHeadOne >{item.name}</WebHeadOne>
          <p className="mt-3 fs-5 ">{item.description}</p>
           
        </div>
        <div className="right" >
          <img src={item.image.asset.url} className="img-fluid" alt="" />
        </div>
      </Banner>

      <div className="details">
        <div className="container">
          <div className="row">
            <div className="col-md-4">
              <div className="right-section p-3 p-md-4">
              <ul className="networkUl">
                  {item.features.map((item, index) => {
                    return (
                      <li key={index}>
                        <p>{item}</p>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-md-8">
              

              <div className="right-content">
                <h3>{item.name}</h3>
                

                <p>
                {item.paratwo}
                </p>


                {/* <LandinUl>
                  {item.features.map((tech, index) => {
                    return (
                      <li key={index}>
                        <p className="m-0">{tech}</p>
                      </li>
                    );
                  })}
                </LandinUl> */}
                 
                 

                <Anchor>
                  <Link to="/contact">Get Free Consultation</Link>
                </Anchor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default  Details;
