import React, {useEffect} from "react";
import react from "../assets/img/icons/react.svg";
import html from "../assets/img/icons/html5.svg";
import css from "../assets/img/icons/css3.svg";
import vue from "../assets/img/icons/vuejs.svg";
import angular from "../assets/img/icons/angularjs.svg";
import flutter from "../assets/img/icons/flutter.svg";
import javascript from "../assets/img/icons/javascript.svg";
import bootstrap from "../assets/img/icons/bootstrap.svg";
import npm from "../assets/img/icons/npm.svg";
import tailwind from "../assets/img/icons/tailwind-css.svg";
import ionic from "../assets/img/icons/ionic.svg";
import next from "../assets/img/icons/next.svg";
import typescript from "../assets/img/icons/typescript.svg";
import three from "../assets/img/icons/three.svg";
import figma from "../assets/img/icons/figma.svg";
import photoshop from "../assets/img/icons/adobe-photoshop.svg";
import laravel from "../assets/img/icons/laravel.svg";
import codeigniter from "../assets/img/icons/codeigniter.svg";
import python from "../assets/img/icons/python.svg";
import php from "../assets/img/icons/php.svg";
import django from "../assets/img/icons/django.svg";
import flask from "../assets/img/icons/flask-svgrepo-com.svg";
import ruby from "../assets/img/icons/ruby.svg";
import node from "../assets/img/icons/node-dot-js.svg";
import express from "../assets/img/icons/express.svg";
import mongo from "../assets/img/icons/mongodb.svg";
import postgres from "../assets/img/icons/postgresql.svg";
import mysql from "../assets/img/icons/mysql.svg";
import nginx from "../assets/img/icons/nginx.svg";
import java from "../assets/img/icons/java.svg";
import microsoft from "../assets/img/icons/microsoft.svg";
import firebase from "../assets/img/icons/firebase.svg";
import wordpress from "../assets/img/icons/wordpress.png";
import meanstack from "../assets/img/icons/meanstack.png";
import mernstack from "../assets/img/icons/mernstack.png";
import pernstack from "../assets/img/icons/pernstack.png";
import wix from "../assets/img/icons/wix.png";
import shopify from "../assets/img/icons/shopify.png"; 
import AOS from "aos";
const Frontend = () => {

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);
  const techData = [
    {
      icon: html,
      name: "HTML",
      link: "frontend/html",
      class: "html",
    },
    {
      icon: css,
      name: "CSS",
      link: "frontend/css",
      class: "css",
    },
    {
      icon: javascript,
      name: "JavaScript",
      link: "frontend/javascript",
      class: "js",
    },
    {
      icon:  bootstrap,
      name: "Bootstrap",
      link: "frontend/bootstrap",
      class: "bootstrap",
    },
    {
      icon: react,
      name: "React",
      link: "frontend/react",
      class: "react",
    },
    {
      icon: angular,
      name: "Angular",
      link: "frontend/angular",
      class: "angular",
    },
    {
      icon: flutter,
      name: "Flutter",
      link: "frontend/flutter",
      class: "flutter",
    },
    {
      icon: npm,
      name: "NPM",
      link: "frontend/npm",
      class: "npm",
    },
    {
      icon: vue,
      name: "Vue.js",
      link: "frontend/vue",
      class: "vue",
    },
    {
      icon: tailwind,
      name: "Tailwind",
      link: "frontend/tailwind",
      class: "tailwind",
    },
    {
      icon: ionic,
      name: "Ionic",
      link: "frontend/iconic",
      class: "iconic",
    },
   
  
    {
      icon: next,
      name: "Next.js",
      link: "frontend/next",
      class: "next",
    },
    {
      icon: typescript,
      name: "Typescript",
      link: "#",
      class: "typescript",
    },
    {
      icon: three,
      name: "Three.js",
      link: "#",
      class: "js",
    },
    {
      icon: figma,
      name: "Figma-Html",
      link: "#",
      class: "figma",
    },
    {
      icon: photoshop,
      name: "PSD to Html",
      link: "#",
      class: "photoshop",
    },
    {
      icon: php,
      name: "PHP",
      link: "#",
      class: "php",
    },
    {
      icon: laravel,
      name: "Laravel",
      link: "#",
      class: "laravel",
    },
    {
      icon: codeigniter,
      name: "Codeigniter",
      link: "#",
      class: "codeigniter",
    },
    {
      icon: python,
      name: "Python",
      link: "#",
      class: "python",
    },
    {
      icon: django,
      name: "Django",
      link: "#",
      class: "django",
    },
    {
      icon:  flask,
      name: "Flask",
      link: "#",
      class: "flask",
    },
    
    {
      icon: ruby,
      name: "Ruby",
      link: "#",
      class: "ruby",
    },
    {
      icon: node,
      name: "Node",
      link: "#",
      class: "node",
    },
    {
      icon: express,
      name: "Express",
      link: "#",
      class: "express",
    },
    {
      icon: mongo,
      name: "MongoDB",
      link: "#",
      class: "mongo",
    },
    {
      icon: postgres,
      name: "PostgreSQL",
      link: "#",
      class: "postgres",
    },
    {
      icon: firebase,
      name: "Firebase",
      link: "#",
      class: "firebase",
    },
    {
      icon: mysql,
      name: "MySQL",
      link: "#",
      class: "mysql",
    },
    {
      icon: nginx,
      name: "NGINX",
      link: "#",
      class: "nginx",
    },
    
    {
      icon: java,
      name: "Java",
      link: "#",
      class: "java",
    },
    {
      icon: microsoft,
      name: "Asp.net",
      link: "#",
      class: "microsoft",
    },
    {
      icon: wordpress,
      name: "Wordpress",
      link: "#",
      class: "wordpress",
    },
    {
      icon: mernstack,
      name: "MERN Stack",
      link: "#",
      class: "mernstack",
    },
    {
      icon: meanstack,
      name: "MEAN Stack",
      link: "#",
      class: "meanstack",
    },
    {
      icon: pernstack,
      name: "PERN Stack",
      link: "#",
      class: "pernstack",
    },
    {
      icon: wix,
      name: "Wix",
      link: "#",
      class: "wix",
    },
    {
      icon: shopify,
      name: "Shopify",
      link: "#",
      class: "shopify",
    }
    


     
  ];
  return (
    <>
      <ul className="tech-ul">
        {techData.map((item, index) => {
          return (
            <li data-aos="fade-up"
            data-aos-delay={50*index} key={index}>
             
                <img src={item.icon} alt="react" className="tech-icon" />
                {item.name}
               
            </li>
          );
        })}

 
      </ul>
    </>
  );
};

export default Frontend;
