import React, {useEffect} from "react";
import AOS from "aos";
import { Div, ExpCard } from "../assets/styles/component-styles";

const Expertise = () => {
  const data = [
    {
      icon: require("../assets/img/health.png"),
      name: "Healthcare",
      link: "#",
      desc: "We offer HIPAA-compliant software solutions and develop healthcare apps for hospitals, clinics and doctors.",
    },
    {
      icon: require("../assets/img/ecom.png"),
      name: "Retail & Ecommerce",
      link: "#",
      desc: "We develop e-commerce apps like real-time chatbots, shopping portals and shopping apps to enhance the end-user shopping experience.",
    },
    {
      icon: require("../assets/img/finance.png"),
      name: "Banking & Finance",
      link: "#",
      desc: "We have developed many high-quality banking and fintech apps like Crypto wallet apps, auditing portals, etc",
    },
    {
      icon: require("../assets/img/travel.png"),
      name: "Travel & Tourism",
      link: "#",
      desc: "We develop customised on-demand travel apps like AR navigation, payment gateways, ERP, kiosks, and eTicketing system.",
    },
    {
      icon: require("../assets/img/media.png"),
      name: "Media & Entertainment",
      link: "#",
      desc: "We develop unique entertainment apps powered by Artificial Intelligence, Augmented Reality (AR), Virtual Reality (VR), etc.",
    },

    {
      icon: require("../assets/img/education.png"),
      name: "Education & E-Learning",
      link: "#",
      desc: " Digitize your education and e-learning activities with smart learning solutions like knowledge based apps, etc.",
    },
  ];

  useEffect(() => {
    AOS.init({
      duration: 800,
    });
  }, []);
  return (
    <>
      {data.map((item, index) => {
        return (
          <Div data-aos="fade-up"
          data-aos-delay={100*index} key={index} className="col-md-4 mt-3">
            <ExpCard>
              <img
                src={item.icon}
                alt="health solutions"
                className="img-fluid"
              />
              <h4>{item.name}</h4>
              <p>{item.desc}</p>
            </ExpCard>
          </Div>
        );
      })}
    </>
  );
};

export default Expertise;
