import styled from "styled-components";
import { blue,white } from "../../components/colors";
import bannerImg from "../../assets/img/curve-new.jpg";


export const Section = styled.section``;
export const Div = styled.div``;
export const HeadingTwo = styled.h2`
     
    font-size: 1.3rem;
    color: $color-black;
    
    text-transform: capitalize;
    letter-spacing: 1px;
    @media all and (max-width: 768px) {
      font-size: 1rem;
      text-align: left;
      margin-top: 1rem;
      font-weight: 500;
      letter-spacing: normal;
      text-transform: unset;
    }
  `;

  export const Container = styled.div`
    min-height: 47vh;    
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #F7F6FC;
    padding: 5.5rem 0;
    @media all and (max-width: 768px) {
      margin-top: 0;
      min-height: auto;
      padding-top: 4.5rem;
    }
  `;
  export const Anchor = styled.div`
    padding-top: 3.5rem;
    @media all and (max-width: 768px) {
      padding-top: 2rem;
    }
    a {
      text-decoration: none;
      color: ${blue};
      font-size: 1.3rem;
      font-weight: 600;
      border: 2px solid ${blue};
      padding: 0.6rem 2rem;
    }
  `;

  export const HeadingOne = styled.h1`
     
    font-size: 3rem;
    font-weight: 600;     
    text-transform: capitalize;
    @media all and (max-width: 768px) {
      font-size: 2rem;
      text-align: left;
    }
  `;

  export const AboutUs=styled.section`
  min-height: 60vh;
  padding: 8rem 0;
  @media all and (max-width: 1600px) {
    padding: 5rem 0;
  }
  @media all and (max-width: 768px) {
    padding: 2rem 0;
  }
  `

  export const H3=styled.h3``;

  export const CustomCard=styled.div`
  background-color: $color-white;
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: relative;
  padding-top: 3rem;
  font-size: 16px;
  h1 {
    font-weight: bold;
  }
  
  &:hover {
    .hover {
      top: 1rem;
      right: 1.5rem;
    }
  }
   
  }
  `

  export const Box=styled.div`
  position: absolute;
  top: 10px;
  right: 1rem;
  height: 3.5rem;
  width: 3.5rem;
  border-radius: 0.4rem;
  display: flex;
  justify-content: center;
  
  align-items: center;
  transition: all 0.2s ease-in;
  .icon {
    color: #fff;
  }
  `;


  export const ServiceCard=styled.div`
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 5rem;  
  background-color: #fff;
  border-top: 7px solid ${blue};

  @media all and (max-width: 768px) {
    margin-top: 2rem;
  }
 
  `

  export const LoadMore=styled.button`
  margin-top: 1rem;
  background-color: ${blue};
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 0.4rem;`;

  export const Technologies=styled.section`
  padding: 3rem 0;
  background-color: #e5eefd;
  @media all and (max-width: 768px) {
    padding: 2rem 0;
  }`;

  export const MainHeading=styled.h1`
  font-weight: bold;
  padding-bottom: 1rem;
  border-bottom: 1px solid $color-gray;
  span {
    color: ${blue};
  }`

  export const HeadingThree=styled.h3`
  font-weight: bold;
  span {
    color: ${blue};
  }`

  export const LandinUl=styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  li {
    display: inline-block;
    margin-right: 0.5rem;
    margin-bottom: 0.9rem;
    padding: 0.4rem;
    background-color: ${blue};
    color: ${white};
    border-radius: 0.4rem;
  }`;

  export const ExpertiseDiv=styled.section`
  padding: 8rem 0;
  background-color: #fff;
  @media all and (max-width: 768px) {
    padding: 2rem 0;
  }
  `;